// TODO: Add support for frontends that don't have any UI (basically, pure JS)
// TODO: Add 'events' to notify widgets they are shown/hidden
// TODO: Think about OLBExit
// TODO: Deal with unauthenticated users (login page)
// TODO: Deal with navigating from cohab to olb


import {Fragment} from './models/fragment';
import {Frontend} from './models/frontend';
import {Page} from './models/page';
import {navigationService} from './navigation-service';
import {SimpleEmitter} from './simple-emitter';
import {widgetActivationService} from './widget-activation.service';
import {frontendsLoadedService} from './frontends-loaded.service';
import { FrontendScripts } from './models/frontend-scripts';

export class StitchService {
    private pageName: string;
    private currentHash: string;
    private oldHash: string;
    private scriptsToBeLoaded = [];
    private totalScriptsLoaded = 0;

    constructor() {
        this.initialize();
    }

    private initialize(): void {
        this.currentHash = window.location.hash;
        this.pageName =  ((window as any).ipb && ((window as any).ipb.pageName)) ? (window as any).ipb.pageName : window.location.pathname.split('/')[4];
        (window as any).ipb.internalState = {
            events: new SimpleEmitter(),
        }
    }

    private loadResource(path: string): Promise<any> {
        switch (path.substr(path.length - 4).toLowerCase()) {
            case '.css':
                return this.loadCSS(path);
            default:
                return this.loadJS(path);
        }
    }

    private loadJS(path: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.onload = resolve;
            script.onerror = reject;
            script.defer = true;
            script.src = path;
            document.body.appendChild(script);
        });
    }

    private loadCSS(path: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const element = document.createElement('link');
            element.href = path;
            element.rel = "stylesheet";
            if ('onload' in element) {
                element.onload = () => resolve();
                if ('onerror' in element) {
                    element.onerror = reject;
                }
                document.head.appendChild(element);
            } else {
                document.head.appendChild(element);
                resolve();
            }
        });
    }

    private hideFrontends(fragments: Array<Fragment>, activateFragmentName: string): void {
        const activeFragmentIndex = fragments.findIndex((fragment: Fragment) => fragment.name === activateFragmentName);
        for (let fragment of fragments) {
            if (fragment.name !== activateFragmentName) {
                if (fragment.frontends) {
                    for (let frontend of fragment.frontends) {
                        const el = this.getFrontendElement(frontend);
                        if (el && el.style.cssText === '') {
                            /**
                             * Check this frontend should not be part of active fragment
                             * if it is part of active fragment, then no need to hide.
                            */
                            if (!this.isFrontendAvailableInActiveFragmentFrontends(fragments, activeFragmentIndex, frontend)) {
                                el.style.cssText = 'display: none';
                                widgetActivationService.notifyWidgetDeactivation(el);
                            }
                        }
                    }
                }
            }
        }
    }

    private isFrontendAvailableInActiveFragmentFrontends(fragments: Array<Fragment>, activeFragmentIndex: number, frontend: Frontend): boolean {
        let flag = false;
        // find activeFragment frontends
        if (activeFragmentIndex > -1) {
            const frontendIndex = fragments[activeFragmentIndex].frontends.findIndex((frontendValue: Frontend) => frontendValue.tag === frontend.tag);
            if (frontendIndex > -1) {
                flag = true;
            }
        }
        return flag;
    }

    private getFrontendElement(frontend: Frontend): HTMLElement {
        const collection = document.getElementsByTagName(frontend.tag);
        return collection && collection[0] as HTMLElement;
    }

    private showFrontend(frontend: Frontend): void {
        const el = this.getFrontendElement(frontend);
        el.style.cssText = '';
        widgetActivationService.notifyWidgetActivation(el);
    }

    private loadFrontend(frontend: Frontend): Promise<void> {
        return new Promise<void>((resolve, reject) => {

            if (this.getFrontendElement(frontend)) {
                resolve();
            } else {

                // Create the DOM element
                const parentElement = document.getElementsByTagName(frontend.parentTag)[0];
                // Must init to null, see https://developer.mozilla.org/en-US/docs/Web/API/Node/insertBefore
                let nextSibling = null;
                Array.from(parentElement.children).forEach(child => {
                    const order = parseInt(child.getAttribute('order'));
                    if (order > frontend.order) {
                        nextSibling = child;
                    }
                });

                const element = document.createElement(frontend.tag);
                element.setAttribute('order', frontend.order.toString());
                parentElement.insertBefore(element, nextSibling);


                // Load the scripts
                const loadNextScript = (index: number) => {
                    if (frontend.scripts && frontend.scripts[index] && frontend.scripts[index].path) {
                        const script = frontend.scripts.sort((a, b) => {
                            return a.order - b.order;
                        })[index].path;
                        this.loadResource(script).then(() => {
                            this.incrementTotalScriptsLoaded(script);
                            this.notifyFrontendsLoaded();
                            loadNextScript(++index);
                        });
                        
                    } else {
                        resolve();
                    }
                }
                loadNextScript(0);
            }
        });
    }

    private toggleFrontend(): void {
        this.resetScriptsToBeLoaded();
        this.resetTotalScriptsLoaded();
        
        const pages = (window as any).ipb.pages as Array<Page>;
        let currentPage;

        for (let page of pages) {
            if (page.name === this.pageName) {
                currentPage = page;
                break;
            }
        }
        const fragments = currentPage.fragments;

        let activeFragment;
        try {
            // default use case login page not having hash
            if ((this.pageName === (window as any).ipb.pageName ) && !window.location.hash) {
                activeFragment = (window as any).ipb.activeFragment; // TODO read from index html
            }else {
                activeFragment = window.location.hash.split('/').slice(1)[0];
            }
        } catch (e) {
        }

        activeFragment = activeFragment || fragments[0]?.name;

        let frontends;
        for (let fragment of fragments) {
            if (fragment.name === activeFragment) {
                frontends = fragment.frontends;
            }
        }

        // use case deep linking  where # {fragment} not valid load default login page
        if((this.pageName === (window as any).ipb.activeFragment) && !frontends){
            activeFragment = (window as any).ipb.activeFragment;
            for (let fragment of fragments) {
                if (fragment.name === activeFragment) {
                    frontends = fragment.frontends;
                }
            }
        }
        
        if (frontends) {
            frontends.sort((a, b) => {
                return a.order - b.order;
            });

            this.setScriptsToBeLoaded(frontends);

            frontends.forEach(frontend => {
                this.loadFrontend(frontend).then(() => {
                    this.showFrontend(frontend);
                    this.hideFrontends(fragments, activeFragment);
                });
            });
        } else {
            navigationService.ignoreHashChange = true;
            window.location.assign(window.location.origin + window.location.pathname);
        }
        if (currentPage.alwaysDisplayedFrontends) {
            currentPage.alwaysDisplayedFrontends.forEach(frontend => {
                this.loadFrontend(frontend).then(() => {
                    this.showFrontend(frontend);
                });
            });
        }
        
    }

    private notifyFrontendsLoaded(): void {
        if (this.scriptsToBeLoaded.length > 0 && 
            this.scriptsToBeLoaded.length === this.totalScriptsLoaded) {
            frontendsLoadedService.notifyFrontendsLoaded();
        }
    }

    private setScriptsToBeLoaded(frontends: Array<Frontend>): void {
        if (Array.isArray(frontends) && frontends.length > 0) {
            const frontend = frontends[0];
            if (Array.isArray(frontend.scripts)) {
                frontend.scripts.forEach((script) => this.addScriptsToBeLoaded(script));
            }
        }
    }

    private addScriptsToBeLoaded(script: FrontendScripts): void {
        if (script.path) {
            this.scriptsToBeLoaded.push(script.path);
        }
    }

    private resetScriptsToBeLoaded(): void {
        this.scriptsToBeLoaded = [];
    }

    private resetTotalScriptsLoaded(): void {
        this.totalScriptsLoaded = 0;
    }

    private incrementTotalScriptsLoaded(scriptPath: string): void {
        if (this.scriptsToBeLoaded.indexOf(scriptPath) > -1) {
            this.totalScriptsLoaded++;
        }
    }

    public updateHash(hash: string): void {
        this.oldHash = this.currentHash;
        this.currentHash = hash;
    }

    public onNavigated(): void {
        const ignoreHash = navigationService.ignoreHashChange;
        this.updateHash(window.location.hash);
        const canNavigate = navigationService.canNavigate(
            () => {
                const stitchService = new StitchService;
                stitchService.toggleFrontend();
            },
            () => {
                navigationService.ignoreHashChange = true;
                window.location.assign(this.oldHash);
            });
        if (!canNavigate && ignoreHash) {
            return;
        }
        if(canNavigate && !ignoreHash) {
            this.toggleFrontend();
        }
    }

}



